.Stats .swiper-slide.follows-slide {
  align-items: flex-end;
  background-color: transparent;
}

.follow-backdrop .swiper-wrapper {
  background-color: rgba(0,0,0,0.0);
  transition: all 0.1s ease-in-out 0.0s;
}

.follow-backdrop.open .swiper-wrapper {
  transition: all 0.2s ease-in-out 0.21s;
  background-color: rgba(0,0,0,0.15);
  touch-action: none;
}

.Follows {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
}

.follows-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  max-width: 748px;
  min-width: 280px;
  min-height: calc(100% - 48px);
  overflow-x: scroll;
  overflow-y: visible;
  background-color: #f0d2cf;
  box-shadow: inset 0px 0px 40px 1px rgba(0, 0, 0, 0.05);
/*  background-color: #444a61;*/
}

.Follows .search {
  background-color: #fff;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  width: 100%;
  max-width: 748px;
  min-width: 280px;
  height: 48px;
  position: relative;
}

.Follows .search input {
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  text-align: center;
  background-color: #fcfcfc;
  box-shadow: inset 0px -10px 10px transparent;
  transition: box-shadow 0.5s;
}
.Follows .search input:focus {
/*  border-bottom: 1px solid #5fa08d21;*/
  box-shadow: inset 0px -10px 10px #5f9ea00f;
}

.Follows .search input:not(:placeholder-shown) ~ .Search {
  display: none;
}

.Follows .search input:focus ~ .Search {
  display: none;
}

.Follows .search .Search {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  cursor: pointer;
  pointer-events: none;
}

.Follows .search .Search svg {
  fill: #ccc;
  width: 28px;
  height: 28px;
}

@media only screen and (max-width: 800px) {
  .Follows .search input {
    padding: 8px 24px;
    text-align: center;
  }
}

.Follows .players-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 16px 16px 40px;
  width: 100%;

/*  height: 100;*/
  
}

.Follows .player {
  padding: 4px 16px;
  margin: 4px;
  border-radius: 32px;
  background-color: #f0d2cf;
  color: #c55e5e;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0 0 0 2px transparent;
  transition: box-shadow 0.2s;
}

@media (hover: hover) {
  .Follows .player:hover {
    opacity: 0.9;
    background-color: #eec3be;
  }
}

.Follows .player:active {
  opacity: 0.9;
  background-color: #eec3be;
}

.Follows .player.selected {
  background-color: #eec3be;
  color: crimson;
  box-shadow: inset 0px 0px 10px #c55e5e1f;
}
