.Stats {
  background-color: #f37d7de0; /*#ff1961;*/
  width: 100vw;
  height: calc(100dvh - 66px);
  transform: translateY(calc(100dvh - 66px));
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  position: fixed;
  bottom: -66px;
  left: 0;
  z-index: 40;
}

.Stats.open {
  transform: translateY(66px);
  top: 0;
  opacity: 1;
}

.Stats .swiper {
  height: 100%;
  width: 100%;
}

.Stats .swiper-slide {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Stats .CloseBtn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.Stats .CloseBtn.hidden {
  /* for general transition styles see CloseBtn.css */
  opacity: 0;
  transition: all 0s
}

@media only screen and (max-width: 600px) {
  .Stats .CloseBtn {
    position: absolute;
    top: 12px;
    right: 0;
  }
}

.Stats .stats-h2 {
  color: crimson;
  background: -webkit-linear-gradient(40deg, #f37d7d, crimson);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 24px 32px;
  margin: 0;
  font-weight: 100;
  text-align: center;
  font-size: calc(2vw + 1.8rem);
  width: 100%;
  max-width: 748px;
  min-width: 280px;
  border-bottom: 1px solid #efefef;
}

.Stats .stats-h2 span { 
  position: relative;
}

.Stats .scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  clear: left;
}

.Stats .scroll-container > div {
  width: 100%;
  max-width: 748px;
  min-width: 280px;
}
