.NewsImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vw;
  transform: scale(0.5);
  transform-origin: top center;
  transition: all 0.5s;
  margin-top: 4vh;
  z-index: 1;
}

.NewsImageContainer .cinema-backdrop {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  transform: scale(2) translateY(20vh);
  transition: opacity 0.3s;
  opacity: 0;
  z-index: -9;
}

.NewsImageContainer.cinema-view img  {
  transform: scale(2) translate(0, 25vh);
  border-radius: 0px;
  z-index: 10;
}

.NewsImageContainer.cinema-view .cinema-backdrop {
  opacity: 1;
  z-index: 9;
  pointer-events: all;
}

.NewsImageLink.open .NewsImageContainer {
  transform: scale(0.5);
}
