.WordModal {
  transform: scale(1);
  transform-origin: left top;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:100;
  pointer-events: none;
}

.WordModal .transition-helper {
  transform: scale(1);
  opacity: 0;
  visibility: hidden;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.WordModal .word-container { 
  max-width: 100vw;
}

.WordModal .scroll-handle {
  display: flex;
  width: 50vw;
  justify-content: center;
  position: absolute;
  top: 25vh;
}

.WordModal .scroll-handle div {
  position: relative;
  width: 36px;
  height:36px;
  border-radius: 50%;
  background-color: #777;
}

.WordModal .scroll-handle div::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 14px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #afafaf;
    transform: rotate(90deg);
}

.WordModal .scroll-handle div::before {
    content: '';
    position: absolute;
    left: 17px;
    top: 14px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #afafaf;
    clear: both;
    transform: rotate(270deg);
}

.word-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(255,255,255,0.95);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  z-index: 1;
}

.word-backdrop.open {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.WordModal .word:before,
.WordModal .word:after {
  display: none;
}

.WordModal .word {
  font-size: 20vw;
}
.WordModal .can-scroll .word {
  overflow-x: auto;
  max-width: 100vw;
  padding: 0 32px;
}
