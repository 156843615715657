.Accordion {
  max-height: 0px;
  min-height: 0px;
  overflow: hidden;
  transition: all 0.2s;
}

.filters ~ .Accordion {
  width: 100vw;
}

.open ~ .Accordion {
  max-height: 100%;
  min-height: 100%;
}

.filters.open ~ .Accordion {
  max-height: 50%;
  min-height: 50%;
}