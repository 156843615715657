.NewsListContainer {
  text-align: left;
  height: 100vh;
  height: 100dvh;
  max-height: 900px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.NewsList {
  display: flex;
  flex-wrap: wrap;
  width: 1024px;
  padding: 32px;
}

@media only screen and (max-width: 1024px) {
  .NewsList {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .NewsListContainer {
    display: block;
    max-height: unset;
    height: unset;
  }
  .NewsList {
    padding: 32px 16px;
  }
}

.news-list-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  padding-top: 120px;
  justify-content: center;
}
