.ExpandButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  position: relative;
  height: 0px;
}

.ExpandButton a {
  transform: rotateX(0deg);
  transition: all 0.3s;
  transform-origin: center;
  cursor: pointer;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -64px;
}

.ExpandButton a:hover, .ExpandButton a:active {
  background-color: #5fa08d21;

} 

.ExpandButton.open a {
  transform: rotateX(180deg);
}