.Archive {
  background-color: #f9f9f8;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
}

.Archive .archive-section-header {
  margin: 16px;
  padding-bottom: 16px;
  background-color: #fff;
  clear: left;
  border-radius: 8px;
  letter-spacing: 2px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.05);
  color: #974747;
}

.Archive .archive-section-header.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.Archive .archive-section-header h3 {
  font-family: "MyYorker", serif;
  margin: 0;
  padding: 16px 16px 8px;
  color: #614444;
}

.Archive .archive-section-header p {
  margin-top: 2px;
  padding: 0 16px;
}

.Archive .archive-section-header ~ .Accordion {
  margin-top: -16px;
}

.Archive .archive-section-header ~ .Accordion > div {
  margin: 0px 16px;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.05);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.Archive .archive-item {
  padding: 16px;
  clear: left;
  border: 1px solid #e8e8e8;
  border-bottom: 0px solid transparent;
  background-color: #fff;
}

.Archive .archive-item:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.Archive .archive-item img {
  width: 50%;
  max-width: 260px;
  margin: 8px 16px 0 0;
  float: left;
  border-radius: 8px;
}

.Archive .archive-item p {
  margin: 0;
  letter-spacing: 1px;
}

.Archive .archive-stats {
  padding-top: 16px;
  clear: left;
}

.Archive .progress, .Archive .full-progress {
  width: 100%;
  height: 8px;
  background-color: #edeeee;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  overflow: hidden;
}

.Archive .archive-stats-details {
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px;
}

.Archive .progress > div, .Archive .full-progress > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0px;
}

.Archive .full-progress > div {
  background-color: #f37d7d;
  background: -webkit-linear-gradient(10deg, #f37d7d, crimson);
}

.Archive .progress > div {
  background-color: #cca6a6;
  background: -webkit-linear-gradient(10deg, #d6e4e0, #93a5b1);
}

.Archive .load-more {
  clear: left;
}