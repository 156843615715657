.ProgressImage {
  position: relative;
  height: 100%;
  border-radius: 12px;
}

.ProgressImage .HundredP {
  display: none;
}

.ProgressImage img {
  position: relative;
  object-fit: cover;
  object-position: 100% 15%;
  width: 150%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: all 0.1s;
  transform: scale(1);
  z-index: 0;
}

.ProgressImage .progress {
  width: 100%;
  height: 16px;
  background-color: #edeeee;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
  overflow: hidden;
}

.ProgressImage .progress > div {
  background-color: #f37d7d;
  background: -webkit-linear-gradient(10deg, #f37d7d, crimson);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0px;
}

.ProgressImage .feedback {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  color: #080808;
  margin-top: 8px;
}

/* NewsImageLink specific styles below */

.ProgressImage.is-link {
  position: relative;
  overflow: hidden;
}

.ProgressImage.is-link .HundredP {
  display: flex;
}

.ProgressImage.is-link img {
  position: relative;
  border-radius: 12px;
  z-index: 1;
}

.ProgressImage.is-link .jumble-stats {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.ProgressImage.is-link .progress {
  height: 8px;
  border-radius: 2px;
}

.ProgressImage.is-link .feedback {
  display: flex;
  justify-content: space-between;
  font-size: 0.5em;
  margin-top: 0;
}

/* also see `spa/src/cssProgressImage` shinks link to reveal stats */
.NewsImageLink > a:active .ProgressImage img {
  transform-origin: top;
  transform: scale(0.75);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.35, 0, .01, 1);
  transition-delay: 0.2s;
}

.NewsImageLink > a .ProgressImage {
  transition: transform 0s;
}

.NewsImageLink > a .ProgressImage .HundredP {
  opacity: 1;
  transition: opacity 0.4s;
  transition-delay: 0.2s;
}

.NewsImageLink > a:active .ProgressImage .HundredP {
  opacity: 0;
}

.click {
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1000;
}
