.App {
  font-size: 40px;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.puff-loader {
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* #5fa08d21 */
