.Timer {
  font-size: 0.4em;
  position: relative;
  margin-top: 0px;
}
.Timer div {
  position: fixed;
  right: 0;
  top: 66px;
  background-color: #fff; /*#f9f9f9;*/
  z-index: 3;
  padding: 4px 36px 4px 16px;
  border-bottom-left-radius: 12px;
  text-wrap: nowrap;
}

@media only screen and (max-width: 600px) {
  .Timer div {
    padding-right: 24px;
  }
}