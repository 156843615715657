@media only screen and (max-width: 600px) {
  .touch .headline {
    width: calc(100vw - 32px);
    padding-right: 40px;
    justify-content: flex-start;
  }

  .touch .headline.can-scroll{
    position: relative;
  }

  .touch .headline.can-scroll:before {
    content: "•••";
    width: 8px;
    position: absolute;
    right: 24px;
    top: calc(50% - 25px);
    color: #dcdcdd;
    word-break: break-word;
    font-size: 32px;
    line-height: 16px;
  }

  .touch .headline.can-scroll:after {
    content: "•••";
    width: 8px;
    position: absolute;
    right: 8px;
    top: calc(50% - 25px);
    color: #dcdcdd;
    word-break: break-word;
    font-size: 32px;
    line-height: 16px;
  }

  .touch .NewsItem {
    align-items: flex-start;
    padding-left: 16px;
  }

  .touch .NewsImageLink.open .NewsImageContainer  {
    transform: scale(0.5) translateX(-64vw);
    width: 152vw;
  }

  .touch .NewsImageLink.open .NewsImageContainer.cinema-view   {
    transform: scale(0.5) translateX(0vw);
    width: 100vw;
  }

  .touch .NewsImageContainer .cinema-backdrop {
    width: 150%;
  }

  /* hide image on scroll should work for touch and non-touch devices */
  .NewsItem .NewsImageContainer  {
    transition: transform 0.05s, opacity 0.5s, visibility 0.05s linear 0s;
    opacity: 1;
    visibility: visible;
    
  }

  .NewsItem.hide-image .NewsImageContainer  {
    transition: transform 0.05s, opacity 0.5s, visibility 0.0s linear 0.5s;
    opacity: 0;
    visibility: hidden;
  }
}