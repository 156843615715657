.LinkOut {
  display: flex;
  align-items: center;
  margin: 8px;
}

.LinkOut > a {
  cursor: pointer;  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  transition: all 1s;
  background-color: #efefef;
}

.LinkOut > a:hover,
.LinkOut> a:active {
  background-color: #5fa08d21;
  transition: all 0s
}

.LinkOut svg {
  width: 24px;
  height: 24px;
  fill: #3d414f;
}
