.AddFollows {
  background-color: #fff;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  margin-left: 8px;
/*  padding: 4px 8px;*/
}

.AddFollows a {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 1s;  
}

@media (hover: hover) {
  .AddFollows a:hover {
    background-color: #5fa08d21;
    transition: all 0s;
  }
}

.AddFollows a div {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 52px;
  height: 32px;
  transform-style: preserve-3d;
  transition: all 0.2s;
  -webkit-perspective: 500px;
  perspective: 500px;
  padding: 4px 8px;
}

.AddFollows a div.modal-open {
  transform: rotateX(90deg);
}

.AddFollows a div svg {
  position: absolute;
  width: 28px;
  height: 28px;
  margin: 2px;
  fill: #ccc;
  backface-visibility: visible; 
}

.AddFollows a div svg:last-of-type {
  fill: #444a61;
  transform: rotateX(-90deg);
}

@media only screen and (max-width: 600px) {
  .AddFollows a div {
    width: 68px;
    padding: 4px 16px;
  }
}
