.Scores {
  background-color: #f9f9f8;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
}

.Scores .filters {
  width: 100%;
  max-width: 748px;
  min-width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  background-color: #fcfcfc;
}

.Scores .filters .filters-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ccc;
  cursor: pointer;
  padding: 4px 8px;
  background-color: transparent;
  transition: background-color 1s;
}

@media (hover: hover) {
  .Scores .filters .filters-toggle:hover {
    background-color: #5fa08d21;
    transition: background-color 0s;
  }
}

.Scores .filters .filters-toggle svg {
  fill: #ccc;
}

.Scores .filters .filters-toggle.on {
  color: #444a61;
}

.Scores .filters .filters-toggle.on svg {
  fill: #444a61;
}

.Scores .filters .filters-toggle .FilterToggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Scores .filters h3 {
  padding: 0;
  margin: 0 0 0 8px;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 1rem;
}

.Scores .score-row {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 12px;
}

.Scores .score-row div span {
  margin-right: 4px;
  font-size: 0.9rem;
  padding-bottom: 4px;
}

.Scores .cta {
  font-size: 0.8em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Scores .cta a:hover {
  border-bottom: 1px solid #f37d7d;
  cursor: pointer;
}

.Scores .CloseBtn {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media only screen and (max-width: 600px) {
  .Scores .CloseBtn {
    position: absolute;
    top: 16px;
    right: 0;
  }
  .Scores .filters .filters-toggle {
    padding: 4px 16px;
  }
  .Scores .score-row {
    padding: 0 30px 0 20px;
  }
}
