.NewsImageLink {
  width: 33.333%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 850px) {
  .NewsImageLink {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .NewsImageLink {
    width: 100%;
  }
}

.NewsImageLink > a {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 200px;
  outline: none;
  padding: 4px;
}

.NewsImageLink > a img {
  /*object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: transform 0.3s;*/
}

.NewsItemContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0);
  transform-origin: top left;
  transition: opacity 0.2s, transform 0.2s;
  opacity: 0;
  visibility: hidden;
}

.NewsImageLink.open .NewsItemContainer {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.NewsItemContainer .scale-div {
  width: 100vw;
  height: 100%;
  transform: scale(1);
  transform-origin: top left;
  transition: transform 0.2s;
}

.NewsItemContainer .CloseBtn {
  position: absolute;
  top: 1vh;
  right: 1vw;
}