.NewsItem {
  height: 100vh;
  background-color: rgba(255,255,255,1);
  padding-top: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.NewsItem .active {
  transform: scale(1) translate(0);
}

.headline {
  width: 85vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  user-select: none;
}

@media only screen and (max-width: 600px) {
  .NewsItem {
    padding-top: 56vw;
  }
  .headline {
    margin-bottom: 160px;
  }
}

.word-container {
  pointer-events: none;
}

.word-container.over-left,
.word-container.over-right {
  position: relative;
}

.word-container:after {
  position: absolute;
  content: '';
  border-left: 2px solid #f37d7d;
  border-top: 2px solid #f37d7d;
  width: 8px;
  height: 16px;
  left: 0;
  top: 8px;
  opacity: 0;
}

.word-container:before {
  position: absolute;
  content: '';
  width: 0; 
  height: 0; 
  top: 20px;
  left: -1px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent; 
  border-right: 4px solid #f37d7d;
  transform: rotateZ(-90deg);
  opacity: 0;
}

.word-container.over-left:after {
  opacity: 1;
}
.word-container.over-right:after {
  left: unset;
  right: 0;
  border-left: unset;
  border-right: 2px solid #f37d7d;
  opacity: 1;
}
.word-container.over-left:before {
  opacity: 1;
}
.word-container.over-right:before {
  left: unset;
  right: -1px;
  opacity: 1;
}

.word-container.dragging .word,
.word-container.dragging .word-sorted {
  color: #f37d7d;
}

.word-sorted {
  color: #333;
  pointer-events: all;
  white-space: nowrap;
}

.word {
  display: flex;
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  margin: 8px;
  touch-action: none;
  color: #777;
  pointer-events: all;
}

.word.ordered {
  width: auto;
  position: relative;
}

.word.ordered:after {
  content:'';
  background-color: #f37d7d;
  width: calc(100% + 14px);
  height: 2px;
  position: absolute;
  left: -7px;
  bottom: 0;
  transform: translateY(8px);
  transform-origin: top center;
}

.word.ordered.right-edge:before {
  content:'';
  background-color:#f37d7d;
  width: 2px;
  height: 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(7px, 8px);
  transform-origin: top right;
}

.word.ordered.left-edge:before {
  content:'';
  background-color:#f37d7d;
  width: 2px;
  height: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-7px, 8px);
  transform-origin: top left;
}

.word.ordered:not(.left-edge, .right-edge):after {
  width: calc(100% + 18px);
  left: -9px;
}

.word.ordered.left-edge:after {
  width: calc(100% + 16px);
}

.word div {
  display: flex;
}

.word > div {
  display: flex;
}

.animate-sorted-success {
  position: absolute;
  top: 0;
  left: 0;
  font-size: inherit;
  color: #f37d7d;
  height: inherit;
  width: inherit;
  animation: Animated-font normal 0.5s ease-out;
  pointer-events: none;
  transform-origin: center;
}

@keyframes Animated-font {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(30);
    opacity: 0;
  }
}

.drag-overlay {
  position: relative;
  color: #777;
}

.drag-overlay .chunk-count {
  font-family: "Lucida Console", Courier, monospace;
  position: absolute;
  top: -8px;
  right: -16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #f37d7d;
  font-size: 16px;
  color: #fff;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upper-I {
  font-family: Verdana;
  font-size: 0.959em;
}
