.CloseBtn {
  cursor: pointer;  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  transition: all 1s;
  z-index: 1;
}

.CloseBtn:hover,
.CloseBtn:active {
  background-color: #5fa08d21;
  transition: all 0s
}

.CloseBtn svg {
  fill: #3d414f;
}
