.HundredP {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 48px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0d2cf;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
}

.HundredP svg {
  width: 22px;
  height: 22px;
/*  filter: drop-shadow(2px 2px 4px rgb(0 0 0 / 0.2));*/
}
