.LoadMoreBtn {
  display: flex;
  justify-content: center;
  margin: 32px 0 64px;
}

.LoadMoreBtn a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  transition: all 1s;
  background-color: #efefef;
  cursor: pointer;
}

.LoadMoreBtn a:hover, .LoadMoreBtn a:active {
  background-color: #5fa08d21;
  transition: all 0s;
}

.LoadMoreBtnPlaceholder {
  padding: 16px;
}